<template>
  <v-banner
      v-if="showBanner"
      elevation="10"
      :icon="bannerIcon"
      :color="bannerColor"
      rounded
      style="z-index: 2"
      app>
    {{ bannerText }}
    <router-link :to="route">{{ routerText }}</router-link>
    Bij vragen contacteer
    <CopyText :textToCopy="infoMail"
              displayCopyText/>
    of ga via ons
    <router-link :to="contactRoute" target="_blank">contactformulier.</router-link>
  </v-banner>
</template>

<script>
import routeNames from "../../router/RouteNames";
import GeneralConstants from "@/shared/constants/GeneralConstants";
import CopyText from "@/components/shared/actions/CopyText";

export default {
  name: "SubscriptionStatusBanner",
  components: {
    CopyText,
  },
  data() {
    return {
      routeNames: routeNames,
      infoMail: GeneralConstants.INFO_MAIL,
    }
  },
  computed: {
    showBanner() {
      return this.$store.getters["organizationModule/hasTrialOrExpiringSubscription"]
    },
    bannerText() {
      if (this.subDaysLeft > 0) {
        return "Uw " + this.licenseType + " verloopt over " + this.subDaysLeft + (this.subDaysLeft === 1 ? " dag. " : " dagen. ")
      } else {
        return "Uw " + this.licenseType + " is verlopen."
      }
    },
    licenseType() {
      return this.trialFlag ? "proefversie" : "abonnement"
    },
    routerText() {
      return this.trialFlag ? "Koop hier je licentie." : "Verleng hier je licentie."
    },
    trialFlag() {
      return this.$store.state.organizationModule.trial
    },
    subDaysLeft() {
      return this.$store.state.organizationModule.subDaysLeft
    },
    bannerColor() {
      if (this.trialFlag) {
        if (this.subDaysLeft > 7 && this.subDaysLeft <= 14) {
          return 'banner-orange'
        } else if (this.subDaysLeft <= 7) {
          return 'banner-red'
        }
      } else {
        if (this.subDaysLeft > 3 && this.subDaysLeft <= 7) {
          return 'banner-orange'
        } else if (this.subDaysLeft <= 3) {
          return 'banner-red'
        }
      }
      return 'banner-blue'
    },
    bannerIcon() {
      if (this.trialFlag) {
        if (this.subDaysLeft > 7 && this.subDaysLeft <= 14) {
          return 'mdi-clock-alert-outline'
        } else if (this.subDaysLeft <= 7) {
          return 'mdi-clock-alert'
        }
      } else {
        if (this.subDaysLeft > 3 && this.subDaysLeft <= 7) {
          return 'mdi-clock-alert-outline'
        } else if (this.subDaysLeft <= 3) {
          return 'mdi-clock-alert'
        }
      }
      return 'mdi-information-outline'
    },
    route() {
      return this.trialFlag ? {name: routeNames.TRIAL_TO_PAID} : {name: routeNames.SETTINGS_LICENSE, hash: "#subscription"}
    },
    contactRoute(){
      return {name: routeNames.CONTACT}
    }

  }
}
</script>
<style scoped>
a, a:hover, a:visited, a:active {
  color: inherit;
}
</style>
