<template>
  <div class="h-100">
    <header-bar :user="user" @toggle-drawer="$refs.menu.mobileDrawer= !$refs.menu.mobileDrawer" :loading="loading"/>
    <PrimaryNavigationMenu ref="menu"/>
    <v-main>
      <subscription-status-banner/>
      <CustomNavigationMenu :parent-route="parentRoute">
        <PromiseContainer :promises.sync="promises" :show-overlay="false" render-after-load>
          <WhiteFrame class="mb-0">
              <router-view :key="$route.fullPath" v-slot:default />
          </WhiteFrame>
        </PromiseContainer>
      </CustomNavigationMenu>
    </v-main>
  </div>
</template>

<script>
import SubscriptionStatusBanner from "@/components/shared/SubscriptionStatusBanner.vue";
import HeaderBar from "@/components/navigation/HeaderBar.vue";
import PrimaryNavigationMenu from "@/components/navigation/PrimaryNavigationMenu.vue";
import CustomNavigationMenu from "@/components/navigation/CustomNavigationMenu.vue";
import WhiteFrame from "../components/shared/WhiteFrame.vue";
import PromiseContainer from "../components/shared/PromiseContainer.vue";
import RouteNames from "@/router/RouteNames";

export default {
  name: 'Home',
  components: {
    PrimaryNavigationMenu,
    CustomNavigationMenu,
    HeaderBar,
    SubscriptionStatusBanner,
    WhiteFrame,
    PromiseContainer
  },
  data() {
    return {
      promises: [this.$store.dispatch('parameterModule/fetchParamsOrganization'), this.$store.dispatch('organizationModule/fetchModulesFromOrganization')],
      RouteNames,
    }
  },
  computed: {
    user() {
      return this.$store.state.authModule.user
    },
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    loading() {
      return this.$store.state.remainingPromises > 0
    },
    showFrame() {
      return true
    },
    getLowestMatchingRoute() {
      return this.$route
    },
    parentRoute() {
      const route = this.$route.matched[1]
      if (route.name === RouteNames.ROOT) {
        return null
      }

      return route
    }
  }
}
</script>

<style lang="scss">
main {
  div.container {
    max-width: 95%;
  }
}
</style>