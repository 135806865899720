<template>
  <PromiseContainer :promises.sync="promises">
  <v-app-bar
      app
      :height="appBarHeight">
    <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')" v-if="isOnMobile">
      <v-icon color="primary">
        mdi-menu
      </v-icon>
    </v-app-bar-nav-icon>
    <v-spacer/>
    <v-menu v-if="user">
      <template  v-slot:activator="{ on }">
        <v-btn depressed x-large v-on="on" :class="$vuetify.breakpoint.xsOnly? 'mr-n10' : 'px-0'">
        <v-layout column class="text-capitalize text-no-wrap align-end">
          <v-card-title class="text-h6 small-caps font-weight-bold pl-1">{{ formatUserToString }}</v-card-title>
          <v-card-subtitle v-if="user.jobTitle" class="text-subtitle-2 grey--text mt-n6">
            {{ user.jobTitle }}
          </v-card-subtitle>
        </v-layout>
          <Avatar :initials="formatUserToInitials" :id="user.id" :SASKey="SASKey" getter="usersModule/getUserAvatar" action="usersModule/fetchUserAvatar"/>
          <v-icon class="arrow-down-avatar pr-0" :class="$vuetify.breakpoint.xsOnly? null : 'mr-n2'" color="grey" large absolute>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center pb-0">
          <div class="mx-auto text-center">
            <Avatar :initials="formatUserToInitials" :id="user.id" :SASKey="SASKey" getter="usersModule/getUserAvatar" action="usersModule/fetchUserAvatar"/>
            <h3>{{ formatUserToString }}</h3>
            <p class="text-caption mt-1">
              {{ user.email }}
            </p>
            <v-divider class="my-0"></v-divider>
            <router-link :to="{name: RouteNames.PASSWORD_RESET}" class="text-none text--primary text-decoration-none">
              <v-btn depressed text class="w-100 py-7">
                  WIJZIG WACHTWOORD
              </v-btn>
            </router-link>
            <v-divider class="my-0"></v-divider>
            <router-link :to="{name: RouteNames.LOGOUT}" class="text-none text&#45;&#45;primary text-decoration-none">
              <v-btn depressed text class="w-100 py-7">
                  AFMELDEN
              </v-btn>
            </router-link>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>    
    <v-progress-linear
      :active="loading"
      indeterminate
      color="green"
      fixed
      :style="`z-index: 1000; top: ${appBarHeight}px`"
    ></v-progress-linear>
  </v-app-bar>
  </PromiseContainer>
</template>

<script>
import Avatar from "@/components/shared/Avatar.vue";
import {formatUserToInitials} from "@/shared/utils/userUtils";
import RouteNames from "@/router/RouteNames";
import PromiseContainer from "@/components/shared/PromiseContainer";

export default {
  name: "HeaderBar",
  components: {
    Avatar,
    PromiseContainer,
  },
  props: {
    user: Object,
    drawer: Boolean,
    loading: Boolean
  },
  data() {
    return {
      appBarHeight: 64,
      SASKey: null,
      avatar: null,
      promises: [this.$store.dispatch("fileExportModule/fetchSASKey").then(result => this.SASKey = result)],
    }
  },
  computed: {
    RouteNames() {
      return RouteNames
    },
    formatUserToString() {
      return [this.user?.firstName, this.user?.lastName].join(' ')
    },
    formatUserToInitials() {
      return formatUserToInitials(this.user)
    },
    isOnMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  }
}
</script>

<style>
.arrow-down-avatar {
  transform: translate(-15%, 5%);
}
</style>