<template>
  <v-navigation-drawer app
                      v-model="computedDrawer"
                      hide-overlay
                      :width="getSubMenuBarWidth"
                      :permanent="$vuetify.breakpoint.smAndUp"
                      :stateless="true"
                      color="--v-secondary-base"
                      style="z-index: 1000">
    <v-layout column fill-height>
      <div id="hoverCollapse">
        <div flex-grow-0 align-center clickable class="nav-text--text" style="height: '1000px';">
          <v-img class="flex-grow-0 align-center clickable" :height="64" contain src="@/assets/Logo_S2.png" @click="toggleSubDrawer">
            <v-icon class="nav-text--text ml-4" size="32px">mdi-arrow-left</v-icon>
          </v-img>
        </div>
      </div>
      <v-divider class="nav-text"/>
      <v-list class="py-0 nav-menu" density="compact" flat>
        <v-list-item-group :value="getSelectedChildRoute.name" mandatory>
          <router-link v-for="route in getFilteredChildRoutes" :to="route" :key="route.name" class="text-decoration-none">
            <v-list-item :value="route.name" @click="clickNavBar()" class="d-flex py-0 pl-4 pr-2">
              <v-list-item-icon class="ma-0 pa-0 align-self-center">
                <v-icon size="20px">{{ route.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-2 pa-0 text-uppercase small-caps font-weight-bold">
                {{ route.name }}
              </v-list-item-content>
              <v-list-item-icon class="arrow mr-n2">
                <v-icon size="32px">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider/>
          </router-link>
        </v-list-item-group>
      </v-list>
      <CopyRight />
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import CopyRight from './CopyRight.vue'
import RouteNames from "@/router/RouteNames"
import {hasAccessToRoute, organizationHasParameterTeamsByRoute} from "@/shared/utils/authenticationUtils"
import {routeActionIsEnabled} from "@/shared/utils/parameterUtils"


export default {
  name: "MobileSubNavigationMenu",
  components: {
    CopyRight
  },
  props: {
    parentNavItem: {
      type: Object,
      required: false,
      default: null
    },
    drawer: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  events: ["update:drawer"],
  data() {
    return {
      RouteNames,
      subDrawer: false,
    }
  },
  computed: {
    computedDrawer: {
      get() {
        return this.drawer
      },
      set(newValue) {
        this.$emit("update:drawer", newValue)
      }
    },
    isOnMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    getSubMenuBarWidth() {
      if (this.subDrawer && this.drawer && this.isOnMobile) {
        return "100vw"
      }
      return 0
    },
    getFilteredChildRoutes() {
      return this.getChildRoutes.filter(childRoute => hasAccessToRoute({name: childRoute.name}) && organizationHasParameterTeamsByRoute({name: childRoute.name}) && routeActionIsEnabled({name: childRoute.name})) || []
    },
    getChildRoutes() {
      const routes = this.$router.getRoutes()
      return routes.filter(r => r.parent?.name === this.parentNavItem?.route.name)
    },
    getSelectedChildRoute() {
      for (const childRoute of this.getChildRoutes) {
        const resolvedPath = this.$router.resolve({...childRoute, params: this.$route.params}).href
        if (resolvedPath != '/' && this.$route.path.startsWith(resolvedPath)) {
          return childRoute
        }
      }
      return {}
    },

  },
  methods: {
    toggleSubDrawer() {
      if (this.isOnMobile) {
        this.subDrawer = !this.subDrawer
      }
    },
    clickNavBar(){
      this.$emit('toggleDrawer')
      if(this.subDrawer) {
        this.toggleSubDrawer()
      }
    }
  }
}

</script>

<style scoped lang="scss">

#hoverCollapse {
  background-color: $yankee-blue;
}

.nav-menu {
  font-size: 14px;
}

.theme--light.v-list-item {
  .v-list-item__icon :before {
    color: var(--v-secondary-base) !important;
  }
  .arrow {
    margin: 8px 0px 8px 8px;
    :before {
      color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  .v-list-item__content {
    color: $yankee-blue !important;
  }
}
.theme--light.v-list-item.v-list-item--active {
  -webkit-box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);
  -moz-box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);
  box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);

  color: $fossil-gray !important;
  .v-list-item__icon :before {
    color: var(--v-nav-text-active-base) !important;
  }
}

</style>