<template>
  <div class="caption mt-auto pl-1">
    <v-icon size="10px" class="nav-text--text vertical-align-baseline">
      mdi-copyright
    </v-icon>
    <span class="nav-text--text">{{ `Strouwi BV 2019 - ${currentYear}` }}</span>
  </div>
</template>

<script>

export default {
  name: "CopyRight",
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}

</script>