<template>
  <v-row no-gutters :style="cssVars">
    <v-col v-if="showMenu" cols="auto">
      <v-list class="py-0 h-100 nav-menu" density="compact" flat>
        <v-list-item-group :value="getSelectedNavItem" mandatory>
          <router-link v-for="navItem in getNavItems" :to="navItem" :key="navItem.name" class="text-decoration-none">
            <v-list-item :value="navItem.name" class="d-flex py-0 pl-4 pr-2">
              <v-list-item-icon class="ma-0 pa-0 align-self-center">
                <v-icon size="20px">{{ navItem.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-1 pa-0 text-uppercase small-caps font-weight-bold">
                {{ navItem.name }}
              </v-list-item-content>
              <v-list-item-icon class="arrow mr-n2">
                <v-icon size="32px">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider />
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col>
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>

import RouteNames from "@/router/RouteNames";
import {
  hasAccessToRoute,
  organizationHasModuleByRoute,
  organizationHasParameterTeamsByRoute
} from "@/shared/utils/authenticationUtils";
import { routeActionIsEnabled } from "@/shared/utils/parameterUtils";

export default {
  name: "CustomNavigationMenu",
  props: {
    parentRoute: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      RouteNames,
      topSize: 0,
      height: innerHeight
    }
  },
  mounted() {
    // BB-1239 topsize is the amount of pixels on top of the screen, needs to be subtracted from height to get te exact height for the secondary navbar
    // depends on the presence of a subscriptionstatusBanner
    if (this.$store.getters["organizationModule/hasTrialOrExpiringSubscription"]) {
      this.topSize = 140
    } else {
      this.topSize = 65
    }
    this.onResize()
    window.addEventListener('resize', this.onResize);
  },
  computed: {
    cssVars() {
      return {
        'height': this.height + 'px'
      }
    },
    getNavItems() {
      return this.getChildRoutes.filter(
        navItem => hasAccessToRoute({ name: navItem.name })
            && organizationHasParameterTeamsByRoute({ name: navItem.name})
          && routeActionIsEnabled({ name: navItem.name })
          && organizationHasModuleByRoute(navItem)
      ) || []
    },
    getSelectedNavItem() {
      //Check if any of the activator routes of a nav item matches the current route
      //This will make the nav item active
      for (const navItem of this.getNavItems) {
        if (!navItem.meta?.activating_routes) {
          return null
        }

        for (const routeName of navItem.meta.activating_routes) {
          const resolvedPath = this.$router.resolve({ name: routeName, params: this.$route.params }).href
          if (resolvedPath != '/' && this.$route.path.startsWith(resolvedPath)) {
            return navItem.name
          }
        }
      }

      //Check if any of the nav item routes or their subroutes match the current route
      //This will make the nav item active
      for (const navItem of this.getNavItems) {
        const resolvedPath = this.$router.resolve({ ...navItem, params: this.$route.params }).href
        if (resolvedPath != '/' && this.$route.path.startsWith(resolvedPath)) {
          return navItem.name
        }
      }

      return null;
    },
    style() {
      return {
        'z-index': 1000,
        'left': this.getPrimaryNavigationMenuWidth
      }
    },
    hasParentRoute() {
      return !!this.parentRoute
    },
    showMenu() {
      return this.$vuetify.breakpoint.mdAndUp && this.hasParentRoute
    },
    getChildRoutes() {
      const routes = this.$router.getRoutes()
      return routes.filter(r => r.parent?.name === this.parentRoute?.name)
    }
  },
  methods: {
    onResize() {
      this.height = innerHeight - this.topSize
    }
  },
}
</script>

<style scoped lang="scss">
.nav-menu {
  font-size: 12px;
}

.theme--light.v-list-item {
  .v-list-item__icon :before {
    color: var(--v-secondary-base) !important;
  }

  .arrow {
    margin: 8px 0px 8px 8px;

    :before {
      color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  .v-list-item__content {
    color: $yankee-blue !important;
  }
}

.theme--light.v-list-item.v-list-item--active {
  -webkit-box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);
  -moz-box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);
  box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);

  color: $fossil-gray !important;

  .v-list-item__icon :before {
    color: var(--v-nav-text-active-base) !important;
  }
}
</style>
